import { useEffect, useState } from 'react';

import { Modal } from '../../../../components/Modal/Modal';
import { Input } from '../../../../components/Input/Input';

interface Props {
    isOpen: boolean
    onCloseButtonClick?: () => void
    handleRecipeSave: (name: string, description: string) => void
}

export const AddRecipeModal: React.FC<Props> = ({isOpen, onCloseButtonClick, handleRecipeSave}) => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const onCloseModal = () => {
        setName('');
        setDescription('');
        onCloseButtonClick?.();
    }

    const onPrimaryButtonClick = () => {
        handleRecipeSave(name, description);
        setName('');
        setDescription('');
    }

    return <Modal
        className='mini add-food-modal'
        isOpen={isOpen}
        title="Nuovo Alimento/Bevanda"
        onCloseButtonClick={onCloseModal}
        primaryButtonText='Aggiungi'
        primaryButtonDisabled={name === ''}
        onPrimaryButtonClick={onPrimaryButtonClick}
    >
        <div className='text-xs modal-subtitle'> 
            <p>Inserisci il nome e la descrizione della ricetta che hai preparato</p>
        </div>
        <div className='form-wrapper'>
            <Input
                label="Nome*"
                name="name"
                placeholder="Nome ricetta"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            <Input
                label="Descrizione"
                name="description"
                placeholder="Breve descrizione della ricetta"
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
        </div>
    </Modal>
};