import { useEffect, useState } from 'react';

import { Modal } from '../../../../components/Modal/Modal';
import { Input } from '../../../../components/Input/Input';
import { InputSelect } from '../../../../components/Input/InputSelect';
import { getOptionFromLabel } from '../../../../helpers/commonHelpers';
import { beverageValues, foods } from '../../../../utils/Constants';

import './AddFoodModal.scss';
import { InputBeverageSelect } from '../../../../components/Input/InputBeverageSelect';

interface Props {
    isOpen: boolean
    onCloseButtonClick?: () => void
    handleFoodSave: (name: string, quantity: string, type: string) => void
}

export const AddFoodModal: React.FC<Props> = ({isOpen, onCloseButtonClick, handleFoodSave}) => {
    const [name, setName] = useState<string>('');
    const [other, setOther] = useState<string>('');
    const [quantity, setQuantity] = useState<string>('');

    const onCloseModal = () => {
        setName('');
        setOther('');
        setQuantity('');
        onCloseButtonClick?.();
    }

    const onPrimaryButtonClick = (name: string, quantity: string) => {
        const nameToSave = other != '' ? other : name
        let typeToSave = 'food';
        if (quantity.toLowerCase().includes("bicchiere") || quantity.toLowerCase().includes("bicchieri")) {
            typeToSave = 'beverage';
        }
        handleFoodSave(nameToSave, quantity, typeToSave);
        setName('');
        setOther('');
        setQuantity('');
    }

    return <Modal
        className='mini add-food-modal'
        isOpen={isOpen}
        title="Nuovo Alimento/Bevanda"
        onCloseButtonClick={onCloseModal}
        primaryButtonText='Aggiungi'
        primaryButtonDisabled={(name === '' && other === '') || !quantity}
        onPrimaryButtonClick={() => onPrimaryButtonClick(name, quantity)}
    >
        <div className='text-xs modal-subtitle'> 
            <p>Seleziona un Alimento/Bevanda dal menu a tendina e inserisci la quantità in <b>grammi</b> se si tratta di un alimento, in <b>bicchieri</b> nel caso di una bevanda.</p>
            <p style={{marginTop: '8px'}}>Se non trovi l'alimento o la bevanda che cercavi scrivilo nel campo <b>"Altro"</b></p>
        </div>
        <div className='form-wrapper'>
            <InputSelect
                label='Nome*'
                name='name'
                value={getOptionFromLabel(foods, name)}
                placeholder="Seleziona alimento"
                options={foods}
                onChange={(option) => setName(option?.label)}
            />
            <Input
                label="Altro"
                name="other"
                type="text"
                value={other}
                onChange={(e) => setOther(e.target.value)}
            />
            <Input
                label="Quantità* (in grammi o bicchieri)"
                name="quantity"
                type="number"
                suffix="gr"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
            />
            <InputBeverageSelect 
                error={''}
                options={beverageValues}
                value={quantity}
                onSelect={(option) => setQuantity(option.value)}
            />
        </div>
    </Modal>
};