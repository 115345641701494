import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { InputPassword } from '../../components/Input/InputPassword';

import { notifyErrorMessage } from '../../helpers/commonHelpers';

import Logo from "../../images/logo-fun-vegetables.png";

import * as Auth from "../../services/AuthService";
import { setLocalUser } from '../../services/UserService';

import './LoginPage.scss';

function LoginPage() {
    const navigate = useNavigate();

    const [passwordShown, setPasswordShown] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    const login = () => {
        setIsLoading(true);
        setErrorMessage('');
        Auth.login(email, password)
            .then(response => {
                Auth.getUser()
                    .then(user => {
                        setLocalUser(user);
                    })
                navigate('/diario-alimentare');
            })  
            .catch(error => {
                notifyErrorMessage(error.message);
                // if(error.message) {
                //     setErrorMessage(error.message)
                // };
            })
            .finally(() => setIsLoading(false));
    }

    return (  
        <div className="login-page">
            <div className='content-wrapper'>
                <div className="logo-section">
                    <img className='logo-image' src={Logo} alt="Logo Fun Vegetables" />
                </div>
                <div className="form-section">
                    <div className='form-title'>
                        <p className='font-bold title'>Bentornato!</p>
                        <p className='subtitle'>Inserisci le credenziali che hai scelto in fase di registrazione</p>
                    </div>
                    <div className='form'>
                        <Input
                            label="Email*"
                            name="email"
                            placeholder='Inserisci la tua email'
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <InputPassword
                            label='Password*'
                            name='password'
                            placeholder='Inserisci la tua password'
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                        <div className='link-wrapper' >
                            <a className="text-xs link-underlined" href="/recupera-password">Hai dimenticato la password?</a>
                        </div>
                        {errorMessage &&
                            <div className='error-message'>
                                <p>{errorMessage}</p>
                            </div>
                        }
                        <div className='button-wrapper'>
                            <Button 
                                label="Accedi"
                                className="primary-button"
                                onClick={login}
                                isLoading={isLoading}
                                disabled={!email || !password}
                            />
                        </div>
                        <div className='registration-link-wrapper text-xs'>
                            <p>Non sei ancora registrato?</p>
                            <a className='font-bold' href='/registrati'>Registrati ora</a>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer limit={1}></ToastContainer>
        </div>
    );
}

export default LoginPage;