import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format, setDefaultOptions } from "date-fns";
import { it } from 'date-fns/locale'

import { MealCardsSkeleton } from "../../components/Skeletons/MealCardsSkeleton";

import { getMealData } from "../../helpers/mealHelpers";
import { notifyErrorMessage, notifySuccessMessage } from "../../helpers/commonHelpers";

import BreakfastLogo from "../../images/breakfast.png";
import LunchLogo from "../../images/lunch.png";
import DinnerLogo from "../../images/dinner.png";
import SnackLogo from "../../images/snack.png";
import MerendaLogo from "../../images/merenda.png";
import DrinkWaterLogo from "../../images/drink-water.png";
import TrainingLogo from "../../images/training.png";
import { ArrowLeftIcon } from "../../icons/ArrowLeftIcon";

import { addChildrenTraining, addChildrenWater, getChildren, getChildrenMeals, getChildrenTraining, getChildrenWater } from "../../services/ChildrenService";

import { Children } from "../../types/Children";
import { Meal } from "../../types/Meal";
import { Water } from "../../types/Water";
import { Training } from "../../types/Training";

import { trainingValues, waterValues } from "../../utils/Constants";

import { HorizontalCalendar } from "./HorizontalCalendar/HorizontalCalendar";
import { MealCard } from "./MealCard/MealCard";
import { ActivityCard } from "./ActivityCard/ActivityCard";
import './DashboardFoodDiaryPage.scss';

function DashboardFoodDiaryPage() {
    const navigate = useNavigate();
    setDefaultOptions({ locale: it })
    const { childrenId } = useParams<"childrenId">();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isLoadingChildren, setIsLoadingChildren] = useState(true);
    const [isLoadingMeals, setIsLoadingMeals] = useState(true);
    const [children, setChildren] = useState<Children>();
    const [breakfast, setBreakfast] = useState<Meal>();
    const [lunch, setLunch] = useState<Meal>();
    const [dinner, setDinner] = useState<Meal>();
    const [spuntino, setSpuntino] = useState<Meal>();
    const [merenda, setMerenda] = useState<Meal>();
    const [spuntinoSerale, setSpuntinoSerale] = useState<Meal>();
    const [training, setTraining] = useState<string>();
    const [water, setWater] = useState<string>();

    useEffect(() => {
        if (!childrenId) return;
        const formattedDate = format(selectedDate, 'yyyy-MM-dd');
        fetchChildren();
        fetchChildrenMeals(formattedDate);
        fetchChildrenWater(formattedDate);
        fetchChildrenTraining(formattedDate);
    }, [selectedDate])

    const fetchChildren = async () => {
        await getChildren(childrenId!)
            .then(children => {
                setChildren(children);
            })
            .catch(error => {
                navigate('/diario-alimentare')
                notifyErrorMessage('Non puoi visualizzare questo diario alimentare')
            })
            .finally(() => {
                setIsLoadingChildren(false);
            });
    };

    const fetchChildrenMeals = async (date: string) => {
        await getChildrenMeals(childrenId!, date)
            .then(childrenMeals => {
                const breakfast = getMealData(childrenMeals, "Colazione");
                const lunch = getMealData(childrenMeals, "Pranzo");
                const dinner = getMealData(childrenMeals, "Cena");
                const spuntino = getMealData(childrenMeals, "Spuntino");
                const merenda = getMealData(childrenMeals, "Merenda");
                const spuntinoSerale = getMealData(childrenMeals, "Spuntino Serale");
                setBreakfast(breakfast);
                setLunch(lunch);
                setDinner(dinner);
                setSpuntino(spuntino);
                setMerenda(merenda);
                setSpuntinoSerale(spuntinoSerale);
            })
            .catch(error => {

            })
            .finally(() => {
                setIsLoadingMeals(false);
            });
    };

    const fetchChildrenTraining = async (date: string) => {
        await getChildrenTraining(childrenId!, date)
            .then(training => {
                if(training) {
                    setTraining(training.value)
                } else {
                    setTraining(undefined);
                }
            })
            .catch(error => console.log(error))
    };

    const updateChildrenTraining = async (value: string) => {
        const trainingObj: Training = {
            date: format(selectedDate, 'yyyy-MM-dd'),
            value
        };
        addChildrenTraining(childrenId!, trainingObj)
            .then(response => {
                setTraining(response.value)
                // notifySuccessMessage('Il tuo Diario Alimentare è stato aggiornato correttamente!');
            })
            .catch(error => {
                notifyErrorMessage(error.message);
            });
    };

    const fetchChildrenWater = async (date: string) => {
        await getChildrenWater(childrenId!, date)
            .then(water => {
                if(water) {
                    setWater(water.value)
                } else {
                    setWater(undefined);
                }
            })
            .catch(error => console.log(error))
    };

    const updateChildrenWater = async (value: string) => {
        const waterObj: Water = {
            date: format(selectedDate, 'yyyy-MM-dd'),
            value
        };
        addChildrenWater(childrenId!, waterObj)
            .then(response => {
                setWater(response.value)
                // notifySuccessMessage('Il tuo Diario Alimentare è stato aggiornato correttamente!');
            })
            .catch(error => {
                notifyErrorMessage(error.message);
            });
    };

    return <div className="dashboard-food-diary-page">
        <div className='hero'>
            <p className='back-link text-small' onClick={() => navigate('/diario-alimentare')}>
                <ArrowLeftIcon />
                <span>Torna Indietro</span>
            </p>
            <h1 className='title'>Diario Alimentare di {children?.name}</h1>
        </div>
        <div className='content'>
            <HorizontalCalendar 
                date={selectedDate}
                onChange={setSelectedDate}
            />
            {isLoadingMeals || isLoadingChildren ?
                <MealCardsSkeleton /> :
                <div className="food-diary-wrapper">
                    <div className="general-cards-wrapper">
                        <ActivityCard 
                            title="Acqua Bevuta"
                            subtitle="Seleziona la quantità di acqua che hai bevuto nella data selezionata"
                            options={waterValues}
                            selectedValue={water}
                            logo={DrinkWaterLogo}
                            onOptionSelected={(id) => updateChildrenWater(id)}
                        />
                        <ActivityCard 
                            title="Attività Fisica Svolta"
                            subtitle="Indica quanta attività fisica hai svolto nella data selezionata"
                            options={trainingValues}
                            selectedValue={training}
                            logo={TrainingLogo}
                            onOptionSelected={(id) => updateChildrenTraining(id)}
                        />
                    </div>
                    <div className="meals-wrapper">
                        <MealCard 
                            childrenId={childrenId!}
                            name="Colazione"
                            meal={breakfast}
                            date={selectedDate}
                            logo={BreakfastLogo}
                            onMealUpdated={(mealUpdated) => setBreakfast(mealUpdated)}
                        />
                        <MealCard 
                            childrenId={childrenId!}
                            name="Spuntino"
                            meal={spuntino}
                            date={selectedDate}
                            logo={SnackLogo}
                            onMealUpdated={(mealUpdated) => setSpuntino(mealUpdated)}
                        />
                        <MealCard 
                            childrenId={childrenId!}
                            name="Pranzo"
                            meal={lunch}
                            date={selectedDate}
                            logo={LunchLogo}
                            onMealUpdated={(mealUpdated) => setLunch(mealUpdated)}
                        />
                        <MealCard 
                            childrenId={childrenId!}
                            name="Merenda"
                            meal={merenda}
                            date={selectedDate}
                            logo={MerendaLogo}
                            onMealUpdated={(mealUpdated) => setMerenda(mealUpdated)}
                        />
                        <MealCard 
                            childrenId={childrenId!}
                            name="Cena"
                            meal={dinner}
                            date={selectedDate}
                            logo={DinnerLogo}
                            onMealUpdated={(mealUpdated) => setDinner(mealUpdated)}
                        />
                        <MealCard 
                            childrenId={childrenId!}
                            name="Spuntino Serale"
                            meal={spuntinoSerale}
                            date={selectedDate}
                            logo={SnackLogo}
                            onMealUpdated={(mealUpdated) => setSpuntinoSerale(mealUpdated)}
                        />
                    </div>
                </div>
            }
        </div>
    </ div>
}

export default DashboardFoodDiaryPage;