import { useState } from 'react';

import { Modal } from '../../../components/Modal/Modal';
import { Input } from '../../../components/Input/Input';
import { InputSelect } from '../../../components/Input/InputSelect';

import { notifyErrorMessage } from '../../../helpers/commonHelpers';

import { addUserChildren, getLocalUser } from '../../../services/UserService';

import { Children } from '../../../types/Children';
import { User } from '../../../types/User';
import './AddChildrenModal.scss';

const initialChildren: Children = {
    name: '',
    weight: '',
    height: '',
}

interface Props {
    isOpen: boolean
    onCloseButtonClick?: () => void
    onSuccess: (children: Children) => void
}

export const AddChildrenModal: React.FC<Props> = ({isOpen, onCloseButtonClick, onSuccess}) => {
    const [children, setChildren] = useState<Children>(initialChildren);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChildrenChange = (name: keyof Children, value: string) => {
        setChildren(prevChildren => ({
            ...prevChildren,
            [name]: value,
        }));
    };

    const handleChildrenSave = async (children: Children) => {
        const localUser: User = getLocalUser();
        if(localUser && localUser.id) {
            setIsLoading(true);
            addUserChildren(localUser.id, children)
                .then(childrenAdded => {
                    onSuccess(childrenAdded);
                    setChildren({});
                    // setChildrens(prevChildrens => [...prevChildrens, childrenAdded])
                })
                .catch(error => {
                    notifyErrorMessage('Qualcosa è andato storto. Riprova più tardi.');
                })
                .finally(() => setIsLoading(false))
        }
    }

    const onCloseModal = () => {
        onCloseButtonClick?.();
    }

    const onPrimaryButtonClick = () => {
        handleChildrenSave(children);
    }

    return <Modal
        className='mini add-children-modal'
        isOpen={isOpen}
        title="Aggiungi Bambino/a"
        onCloseButtonClick={onCloseModal}
        primaryButtonText='Aggiungi'
        primaryButtonLoading={isLoading}
        primaryButtonDisabled={!children.name || isLoading}
        onPrimaryButtonClick={onPrimaryButtonClick}
    >
        <div className='form-wrapper'>
            <Input
                label="Nome*"
                name="name"
                placeholder='Inserisci il nome'
                type="text"
                value={children.name}
                onChange={(e) => handleChildrenChange('name', e.target.value)}
            />
            <Input
                label="Peso"
                name="weight"
                placeholder='Inserisci il peso'
                suffix="kg"
                type="number"
                value={children.weight?.toString()}
                onChange={(e) => handleChildrenChange('weight', e.target.value)}
            />
            <Input
                label="Altezza"
                name="height"
                placeholder="Inserisci l'altezza"
                suffix="cm"
                type="number"
                value={children.height?.toString()}
                onChange={(e) => handleChildrenChange('height', e.target.value)}
            />
            {/* <InputSelect
                label='Nome*'
                name='name'
                value={getOptionFromLabel(foods, name)}
                // error={errors.gender?.message}
                placeholder="Seleziona alimento"
                options={foods}
                onChange={(option) => setName(option.label)}
            /> */}
        </div>
    </Modal>
};