export const ArrowLeftIcon = () => {
    return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_628_2009)">
            <path d="M10.725 5.9999H1.27502M1.27502 5.9999L6.00002 10.7249M1.27502 5.9999L6.00002 1.2749" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_628_2009">
                <rect width="12" height="12" fill="white" />
            </clipPath>
        </defs>
    </svg>
}